import React, { Component } from 'react';
import ReactGA from 'react-ga';
import firebase from 'firebase';
import './App.css';

// Initialize Google Analytics
ReactGA.initialize('UA-125400251-2');

/* Init */
firebase.initializeApp({
  apiKey: "AIzaSyCHhjHKg8nOyZyoUs6dbi8YfjRc-W7nx9w",
  authDomain: "san-francigy.firebaseapp.com",
  databaseURL: "https://san-francigy.firebaseio.com",
  projectId: "san-francigy",
});

let db = firebase.database();
let ref = db.ref("/aqi");

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isLoaded: false,
      isErrored: false,
      aqi: null,
    }
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
    ref.once("value", (data) => {
      this.setState({
        isLoaded: true,
        isLoading: false,
        isErrored: false,
        aqi: data.val(),
      })
      console.log(data.val())
    }, () => {
      this.setState({isErrored: true})
    });
  }
  renderMetrics = () => {
    if (this.state.isErrored) {
      return <ErroredMetrics/>;
    }
    if (this.state.isLoading) {
      return <LoadingMetrics/>
    }
    return <LoadedMetrics aqi={this.state.aqi}/>

  }
  render() {
    return (
      <div className="App">
        <div className="App-body">
          <header>
            <div className="Header-title">
              San Francigy
            </div>
            <div className="Header-desc">
              San Francisco's Air Quality Index, converted to cigs
            </div>
          </header>
          {this.renderMetrics()}
		  <footer>Built by Collin, Co-Founder of <a href='https://www.useStable.com?utm_source=sanfrancigy'>Stable</a></footer>
        </div>
      </div>
    );
  }
}

const LoadingMetrics = () => (
  <div>
    Loading
  </div>
)

const ErroredMetrics = () => (
  <div>
    Errored
  </div>
)

class LoadedMetrics extends Component {
  componentDidMount() {
    var canvas = document.getElementById('canvas');
    if (canvas.getContext) {
      let topButt = canvas.getContext('2d');
      topButt.fillStyle = "#ffa31a";
      topButt.fillRect(0, 0, 60, 20);

      let bottomButt = canvas.getContext('2d');
      bottomButt.fillStyle="#cc7a00";
      bottomButt.fillRect(0, 20, 60, 10);

      let buttDividerTop = canvas.getContext('2d');
      buttDividerTop.fillStyle = "#999900"
      buttDividerTop.fillRect(60, 0, 10, 5);

      let buttDivierMid = canvas.getContext('2d');
      buttDivierMid.fillStyle = "#b3b300";
      buttDivierMid.fillRect(60, 5, 10, 15);

      let buttDividerBottom = canvas.getContext('2d');
      buttDividerBottom.fillStyle = "#cccc00";
      buttDividerBottom.fillRect(60, 20, 10, 10);

      let shaftTop = canvas.getContext('2d');
      shaftTop.fillStyle = "#ffffe6"
      shaftTop.fillRect(70, 0, 230, 20);

      let shaftBottom = canvas.getContext('2d');
      shaftBottom.fillStyle = "#eaeadc";
      shaftBottom.fillRect(70, 20, 230, 10);

      let burnHard = canvas.getContext('2d');
      burnHard.fillStyle = "red";
      burnHard.fillRect(300, 0, 5, 30);

      let burnLight = canvas.getContext('2d');
      burnLight.fillStyle = "#ff9900";
      burnLight.fillRect(305, 0, 5, 30);
    }
  }
  invLinear = (AQIhigh, AQIlow, Conchigh, Conclow, a) => {
  	let c;
  	c=((a-AQIlow)/(AQIhigh-AQIlow))*(Conchigh-Conclow)+Conclow;
  	return c;
  }
  aqiToP25 = (a) => {
    let concCalc;
    if (a>=0 && a<=50){
  		concCalc=this.invLinear(50,0,15.4,0,a);
  	}
  	else if (a>50 && a<=100){
  		concCalc=this.invLinear(100,51,35.4,15.5,a);
  	}
  	else if (a>100 && a<=150){
  		concCalc=this.invLinear(150,101,65.4,35.5,a);
  	}
  	else if (a>150 && a<=200){
  		concCalc=this.invLinear(200,151,150.4,65.5,a);
  	}
  	else if (a>200 && a<=300){
  		concCalc=this.invLinear(300,201,250.4,150.5,a);
  	}
  	else if (a>300 && a<=400){
  		concCalc=this.invLinear(400,301,350.4,250.5,a);
  	}
  	else if (a>400 && a<=500){
  		concCalc=this.invLinear(500,401,500.4,350.5,a);
  	}
  	else{
  		concCalc=500.4;
  	}
  	return concCalc;
  }
  p25ToCigs = (aqi) => {
    return ((44 / 950) * this.aqiToP25(aqi)).toFixed(1)
  }
  render() {
    return (
      <div className="Metrics-container">
        <div className="AQI-container">
          {this.props.aqi}
        </div>

        <canvas id="canvas" width="310" height="30"/>
        <div id="smoke">
          <span className="s0"></span>
          <span className="s1"></span>
          <span className="s2"></span>
          <span className="s3"></span>
          <span className="s4"></span>
          <span className="s5"></span>
          <span className="s6"></span>
          <span className="s7"></span>
          <span className="s8"></span>
          <span className="s9"></span>
        </div>
        <div id="smoke2">
          <span className="s0"></span>
          <span className="s1"></span>
          <span className="s2"></span>
          <span className="s3"></span>
          <span className="s4"></span>
          <span className="s5"></span>
          <span className="s6"></span>
          <span className="s7"></span>
          <span className="s8"></span>
          <span className="s9"></span>
        </div>
        <div id="smoke3">
          <span className="s0"></span>
          <span className="s1"></span>
          <span className="s2"></span>
          <span className="s3"></span>
          <span className="s4"></span>
          <span className="s5"></span>
          <span className="s6"></span>
          <span className="s7"></span>
          <span className="s8"></span>
          <span className="s9"></span>
        </div>
        <div className="Metrics-text">
          You smoked {this.p25ToCigs(this.props.aqi)} cigs today!
        </div>
      </div>
    )
  }
}

export default App;
